import React, { useEffect } from 'react'
import TabNameContainer from './TabNameContainer'

const Templates = () => {
  useEffect(() => {
    document.title = 'Templates'
  }, [])
  return <TabNameContainer />
}

export default Templates
