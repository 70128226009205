import React, { useEffect } from 'react'
import TabNameContainer from './TabNameContainer'

const Projects = () => {
  useEffect(() => {
    document.title = 'Projects'
  }, [])

  return (
    <div>
      <TabNameContainer />
      <div className='project-card'></div>
    </div>
  )
}

export default Projects
