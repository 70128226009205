import React, { useEffect } from 'react'
import TabNameContainer from './TabNameContainer'

const Archive = () => {
  useEffect(() => {
    document.title = 'Archive'
  }, [])
  return <TabNameContainer />
}

export default Archive
