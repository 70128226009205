import React, { useState, useEffect, useMemo } from 'react'
import './custom.scss'
import { useNavigate } from 'react-router-dom'
import TabNameContainer from './TabNameContainer'
import SortDropdown from 'components/layout-options/SortDropdown'
import ListGridToggle from 'components/layout-options/ListGridToggle'
import AddNewUser from './add-popup/AddNewUser'
import AddToClient from './add-popup/AddToClient'
import { useData } from 'context/DataContext'
import { capitalizeFirstLetterOfEachWord, getUserRoleFromLocalStorage } from 'common/helpers'

const options = ['All', 'Client', 'Role', 'Date Added']

const Members = () => {
  const navigate = useNavigate()
  const userRole = getUserRoleFromLocalStorage()
  const [sortOption, setSortOption] = useState('All')
  const [openPopupForUserId, setOpenPopupForUserId] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { members, clients } = useData()

  const handleSortChange = (selectedOption) => {
    setSortOption(selectedOption)
  }

  useEffect(() => {
    document.title = 'Members'
  }, [])

  const sortedMembers = useMemo(() => {
    let sorted = [...members]

    if (sortOption === 'All') {
      return sorted
    }

    if (sortOption === 'Client') {
      sorted = sorted.sort((a, b) => {
        const aClientNames = clients
          .filter((client) => client.user_id === a.user_id)
          .map((client) => client.client_name)
          .join(', ')
        const bClientNames = clients
          .filter((client) => client.user_id === b.user_id)
          .map((client) => client.client_name)
          .join(', ')
        return aClientNames.localeCompare(bClientNames)
      })
    }

    if (sortOption === 'Role') {
      sorted = sorted.sort((a, b) => a.user_role - b.user_role)
    }

    if (sortOption === 'Date Added') {
      sorted = sorted.sort((a, b) => new Date(a.added_at) - new Date(b.added_at))
    }

    return sorted
  }, [sortOption, members, clients])

  return (
    <div>
      <TabNameContainer />
      <div className='control-container'>
        <SortDropdown onSelect={handleSortChange} options={options} selectedOption={sortOption} />
        <div className='btn-container'>
          <ListGridToggle />
          {userRole === 'Admin' && (
            <button className='btn-add' onClick={() => setIsModalOpen(true)}>
              + Add New User
            </button>
          )}
        </div>
      </div>
      <table className='members-table'>
        <thead>
          <tr>
            <th>Member Name</th>
            <th>Role</th>
            <th>Clients</th>
          </tr>
        </thead>
        <tbody>
          {sortedMembers.map((member) => {
            const userClients = clients
              .filter((client) => client.user_id === member.user_id)
              .map((client) => capitalizeFirstLetterOfEachWord(client.client_name))
              .join(', ')

            return (
              <>
                <tr key={member.user_id}>
                  <td className='clickable' onClick={() => navigate(`/dashboardnew/membersnew/${member.user_id}`)}>
                    {member.user_name}
                  </td>
                  <td>{member.user_role === 3 ? 'User' : 'Admin'}</td>
                  <td>{userClients || ''}</td>
                  {userRole === 'Admin' && (
                    <td>
                      <button className='add-client-btn' onClick={() => setOpenPopupForUserId(member.user_id)}>
                        + Add to Client
                      </button>
                      {openPopupForUserId === member.user_id && <AddToClient onClose={() => setOpenPopupForUserId(null)} member={member} />}
                    </td>
                  )}
                </tr>
              </>
            )
          })}
        </tbody>
      </table>
      <AddNewUser isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </div>
  )
}

export default Members
