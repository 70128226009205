import React, { useState } from 'react'
import gridIcon from '../../assets/images/grid-view.svg'
import listIcon from '../../assets/images/list-view.svg'

const ListGridToggle = ({ onToggle }) => {
  const [isGridView, setIsGridView] = useState(true)

  const handleToggle = () => {
    setIsGridView(!isGridView)
    if (onToggle) {
      onToggle(!isGridView)
    }
  }

  return (
    <button className='toggle-btn' onClick={handleToggle}>
      <img src={isGridView ? gridIcon : listIcon} alt='Toggle View' />
    </button>
  )
}

export default ListGridToggle
