import React, { useState, useRef } from 'react'
import './modal.scss'
import { getInitials } from 'common/helpers'

const AddNewUser = ({ isOpen, onClose }) => {
  if (!isOpen) return null

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [initials, setInitials] = useState('')
  const [photo, setPhoto] = useState(null)
  const fileInputRef = useRef(null)

  const handleNameChange = (e) => {
    const newName = e.target.value
    setName(newName)
    setInitials(getInitials(newName))
  }

  const triggerFileInput = () => {
    fileInputRef.current.click() // Trigger file input click programmatically
  }

  const handlePhotoChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setPhoto(reader.result)
      }
      reader.readAsDataURL(file)
    }
  }

  return (
    <div className='modal-overlay'>
      <div className='modal-content'>
        <button className='close-btn' onClick={onClose}>
          ×
        </button>
        <h2 style={{ display: 'flex' }}>Add a new user</h2>
        <div className='user-details'>
          <div className='profile-picture' onClick={triggerFileInput}>
            {!photo ? <div className='upload-photo'>{initials}</div> : <img src={photo} alt='User' className='profile-img' />}
            <p>Upload Photo</p>
            <input ref={fileInputRef} type='file' accept='image/*' onChange={handlePhotoChange} hidden />
          </div>
          <div className='form-group'>
            <label>User name:</label>
            <input type='text' value={name} onChange={handleNameChange} />
            <label>User email address:</label>
            <input type='email' value={email} onChange={(e) => setEmail(e.target.value)} />
          </div>
        </div>
        <button className='btn-add-new-user' disabled={!name.trim() || !email.trim()}>
          Add new user
        </button>
      </div>
    </div>
  )
}

export default AddNewUser
