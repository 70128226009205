export const clientRes = [
  {
    user_id: 1,
    client_name: 'FILTRIX',
    client_slug: 'client-1723651796',
    client_thumbnail_url: 'page-1.png',
    client_current_version: '8',
    client_id: 1,
    created_at: '2024-08-14T16:09:57.000Z',
    last_updated: '2024-10-27T08:51:04.000Z',
    version_ids: '1'
  },
  {
    user_id: 1,
    client_name: 'VORTRELLA',
    client_slug: 'client-1724842885',
    client_thumbnail_url: 'page-1.png',
    client_current_version: '1',
    client_id: 2,
    created_at: '2024-08-28T11:01:25.000Z',
    last_updated: '2024-09-01T08:22:47.000Z',
    version_ids: '1'
  },
  {
    user_id: 2,
    client_name: 'ABSTREX',
    client_slug: 'client-1729089704',
    client_thumbnail_url: 'page-1.png',
    client_current_version: '1',
    client_id: 3,
    created_at: '2024-10-16T14:41:44.000Z',
    last_updated: '2024-10-16T14:46:26.000Z',
    version_ids: '1'
  }
]
