import React, { useEffect } from 'react'
import Navbar from 'view/layout-v2'
import { Outlet } from 'react-router-dom'
import './index.scss'
import UserPanel from 'view/user-panel'

const DashBoardNew = () => {
  useEffect(() => {
    document.title = 'Dashboard'
  }, [])
  return (
    <div className='dashboard-container'>
      <Navbar />
      <div className='panel-container'>
        <UserPanel />
        <div className='detail-panel'>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default DashBoardNew
