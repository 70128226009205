export const projectsRes = [
  {
    project_id: 25,
    project_name: 'Medical Device',
    project_slug: 'project-1723651796',
    project_thumbnail_url: 'page-1.png',
    project_current_version: '8',
    project_status: 1,
    templateID: 4,
    client_id: 1,
    created_at: '2024-08-14T16:09:57.000Z',
    last_updated: '2024-10-27T08:51:04.000Z',
    version_ids: '8'
  },
  {
    project_id: 37,
    project_name: 'Blank Playground',
    project_slug: 'project-1724842885',
    project_thumbnail_url: 'page-1.png',
    project_current_version: '1',
    project_status: 1,
    templateID: 0,
    client_id: 2,
    created_at: '2024-08-28T11:01:25.000Z',
    last_updated: '2024-09-01T08:22:47.000Z',
    version_ids: '1'
  },
  {
    project_id: 38,
    project_name: 'D-template',
    project_slug: 'project-1729089704',
    project_thumbnail_url: 'page-1.png',
    project_current_version: '1',
    project_status: 1,
    templateID: 0,
    client_id: 2,
    created_at: '2024-10-16T14:41:44.000Z',
    last_updated: '2024-10-16T14:46:26.000Z',
    version_ids: '1'
  },
  {
    project_id: 38,
    project_name: 'D-template',
    project_slug: 'project-1729089704',
    project_thumbnail_url: 'page-1.png',
    project_current_version: '1',
    project_status: 1,
    templateID: 0,
    client_id: 3,
    created_at: '2024-10-16T14:41:44.000Z',
    last_updated: '2024-10-16T14:46:26.000Z',
    version_ids: '1'
  }
]
