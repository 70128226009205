import React from 'react'
import './index.scss'
import axios from 'axios'
import toast from 'react-hot-toast'
import Cookies from 'js-cookie'
import { Link, useNavigate } from 'react-router-dom'
import knoteslogo from '../../../src/assets/40knots.svg'
import { handleLaunchEditor, deleteCookie } from 'common/helpers'

const Navbar = () => {
  let navigate = useNavigate()

  const handleLogout = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL + '/api/logout'
      const headers = {
        'Content-Type': 'application/json',
        'api-key': process.env.REACT_APP_API_KEY
      }
      await axios.post(apiUrl, {}, { headers: headers })
      localStorage.clear()

      const isRememberMeEnabled = Cookies.get('rememberMe')
      if (!isRememberMeEnabled) {
        deleteCookie('username')
        Cookies.remove('username')
      }
      deleteCookie('user_id')
      deleteCookie('token')
      toast.success('Logout successful')
      navigate('/')
    } catch (error) {
      console.error('Error:', error)
      toast.error('logout failed')
    }
  }

  return (
    <div className='navbar-container'>
      <div className='navBar'>
        <div className='navbar-logo'>
          <Link to='/dashboard'>
            <img src={knoteslogo} alt='Logo' />
          </Link>
          <div className='dash-txt'>Dashboard</div>
        </div>
        <div>
          <button className='btn'>My Account</button>
          <button className='btn-create' onClick={handleLaunchEditor}>
            Create Project
          </button>
          <button className='btn' onClick={handleLogout}>
            Logout
          </button>
        </div>
      </div>
    </div>
  )
}

export default Navbar
