import React, { useState } from 'react'
import './index.scss'
import { NavLink } from 'react-router-dom'
import { getInitials, getUserNameFromLocalStorage, getUserRoleFromLocalStorage } from 'common/helpers'
import AddNewUser from 'view/detail-panel/add-popup/AddNewUser'
import { useData } from 'context/DataContext'

const UserPanel = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const tabsTop = [
    { name: 'Clients', value: 'clients', accessibleTo: ['Admin', 'User'], route: 'clientsnew' },
    { name: 'Projects', value: 'projects', accessibleTo: ['Admin', 'User'], route: 'projectsnew' },
    { name: 'Brand Libraries', value: 'brandLibraries', accessibleTo: ['Admin', 'User'], route: 'brand-librariesnew' },
    { name: 'Templates', value: 'templates', accessibleTo: ['Admin', 'User'], route: 'templatesnew' }
  ]

  const tabsBottom = [
    { name: 'Members', value: 'members', accessibleTo: ['Admin', 'User'], route: 'membersnew' },
    { name: 'Archive', value: 'archive', accessibleTo: ['Admin'], route: 'archivenew' }
  ]

  const userRole = getUserRoleFromLocalStorage()
  const userName = getUserNameFromLocalStorage()
  const { clients, projectsAssociatedWithUser } = useData()

  return (
    <div className='user-panel'>
      <div className='user-panel__info'>
        <div className='user-panel__avatar'>{getInitials(userName)}</div>
        <div className='user-panel__details'>
          <div className='user-panel__name'>{userName}</div>
          <div className='user-panel__role'>{userRole}</div>
          <div className='user-panel__count'>
            {clients.length} clients, {projectsAssociatedWithUser.length} projects
          </div>
        </div>
      </div>
      <nav className='user-panel__nav'>
        <div className='nav-element-container'>
          <div className='container-top'>
            {tabsTop
              .filter((tab) => tab.accessibleTo.includes(userRole))
              .map((tab) => (
                <NavLink key={tab.value} to={tab.route} style={{ textDecoration: 'none' }}>
                  <span>{tab.name}</span>
                </NavLink>
              ))}
          </div>
          <div className='container-bottom'>
            {tabsBottom
              .filter((tab) => tab.accessibleTo.includes(userRole))
              .map((tab) => (
                <NavLink key={tab.value} to={tab.route} activeClassName='active' style={{ textDecoration: 'none' }}>
                  <span>{tab.name}</span>
                </NavLink>
              ))}
            {userRole === 'Admin' && (
              <button className='btn-add-user' onClick={() => setIsModalOpen(true)}>
                + Add New User
              </button>
            )}
          </div>
        </div>
      </nav>
      <AddNewUser isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </div>
  )
}

export default UserPanel
