import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import TabNameContainer from './TabNameContainer'
import AddToClient from './add-popup/AddToClient'
import { capitalizeFirstLetterOfEachWord, getInitials, getUserRoleFromLocalStorage } from 'common/helpers'
import { useData } from 'context/DataContext'
import { getUserIdFromLocalStorage } from 'config/apiConfig'

const MemberDetails = () => {
  const loggedInUser = getUserIdFromLocalStorage()
  const { userId } = useParams()
  const userRole = getUserRoleFromLocalStorage()
  const [isPopupOpen, setPopupOpen] = useState(false)
  const { clients, clientProjects, members } = useData()
  const fileInputRef = useRef(null)
  const [photo, setPhoto] = useState(null)

  const triggerFileInput = () => {
    if (loggedInUser === Number(userId)) fileInputRef.current.click()
  }

  const handlePhotoChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setPhoto(reader.result)
      }
      reader.readAsDataURL(file)
    }
  }

  useEffect(() => {
    document.title = 'Account'
  }, [])

  const member = members.find((m) => Number(m.user_id) === Number(userId))
  const clientsAssociated = clients.filter((client) => Number(client.user_id) === Number(userId))
  const findAssociatedMembers = (clientId) => {
    return clients.filter((client) => client.client_id === clientId).length
  }
  if (!member) {
    return <div>Member not found.</div>
  }

  return (
    <div>
      <TabNameContainer />

      <div className='member-container'>
        <div className='profile'>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className='profile-picture' onClick={triggerFileInput}>
              {!photo ? (
                <div className='avatar'>{getInitials(member.user_name)}</div>
              ) : (
                <img src={photo} alt='User' className='profile-img' />
              )}
              {loggedInUser === Number(userId) && <p>{!photo ? 'Upload Photo' : 'Replace Photo'}</p>}
              <input ref={fileInputRef} type='file' accept='image/*' onChange={handlePhotoChange} hidden />
            </div>
            <div className='profile-info'>
              <p>Name</p>
              <div>{member.user_name}</div>
            </div>
          </div>
          {loggedInUser === Number(userId) && <button className='btn-edit'>Edit</button>}
        </div>

        <div className='member-details'>
          <div className='member-email'>
            <div>
              <p>Email Address</p>
              <div>{member.user_email}</div>
            </div>
            {loggedInUser === Number(userId) && <button className='btn-edit'>Edit</button>}
          </div>
          <div className='member-clients-list'>
            <div className='wrapper'>
              <p>Clients</p>
              {userRole === 'Admin' && (
                <button className='btn-add' onClick={() => setPopupOpen(true)}>
                  + Add to Clients
                </button>
              )}
            </div>
            <div className='bottom-list-container'>
              {clientsAssociated.length ? (
                clientsAssociated.map((client, index) => {
                  return (
                    <div key={index} className='client'>
                      <div className='client-info'>
                        <div className='avatar'>{getInitials(client.client_name)}</div>
                        <div>
                          <p>{capitalizeFirstLetterOfEachWord(client.client_name)}</p>
                          <p style={{ fontSize: '12px', fontWeight: 300, marginTop: '2px' }}>
                            {`${
                              clientProjects.filter((item) => item.client.client_id == client.client_id)[0].projects?.length
                            } Projects, ${findAssociatedMembers(client.client_id)} Members`}
                          </p>
                        </div>
                      </div>
                      {userRole === 'Admin' && <button className='remove-btn'>Remove from Client</button>}
                    </div>
                  )
                })
              ) : (
                <div style={{ marginTop: '50px' }}>{member.user_name} has not been added to any clients yet.</div>
              )}
            </div>
          </div>
        </div>
      </div>
      {isPopupOpen && <AddToClient onClose={() => setPopupOpen(false)} member={member} />}
    </div>
  )
}

export default MemberDetails
