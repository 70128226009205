import { useState, useEffect, useRef } from 'react'
import sortArrow from '../../assets/images/sort-arrow.svg'
import arrowOpen from '../../assets/images/arrow-open.png'
import './index.scss'

const SortDropdown = ({ onSelect, selectedOption, options, showSortIcon = false }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selected, setSelected] = useState(selectedOption)
  const dropdownRef = useRef(null)

  const handleSelect = (value) => {
    setSelected(value)
    if (onSelect) {
      onSelect(value)
    }
    setIsOpen(false)
  }

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev)
  }

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  return (
    <div className='dropdown-container' ref={dropdownRef}>
      <div className='dropdown'>
        <div className='dropdown-selected' onClick={toggleDropdown}>
          {showSortIcon && <img src={sortArrow} alt='Sort' className='sort-icon' onClick={toggleDropdown} />}
          {selected}
          <img src={arrowOpen} alt='Expand' className='arrow-icon' />
        </div>
        {isOpen && (
          <div className='dropdown-options'>
            {options.map((option) => (
              <div key={option} className='dropdown-option' onClick={() => handleSelect(option)}>
                {option}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default SortDropdown
