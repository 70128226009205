import React, { useState, useEffect } from 'react'
import TabNameContainer from './TabNameContainer'
import './custom.scss'
import SortDropdown from 'components/layout-options/SortDropdown'
import ListGridToggle from 'components/layout-options/ListGridToggle'
import { capitalizeFirstLetterOfEachWord, getInitials, getUserRoleFromLocalStorage } from 'common/helpers'
import { useData } from 'context/DataContext'

const options = ['Alphabetical', 'Date Added', 'Date Modified']

const ClientsPage = () => {
  const userRole = getUserRoleFromLocalStorage()
  const { clientProjects } = useData()

  const [sortOption, setSortOption] = useState('Alphabetical')
  const [sortedProjects, setSortedProjects] = useState(clientProjects)

  const handleSortChange = (selectedOption) => {
    setSortOption(selectedOption)
  }

  useEffect(() => {
    document.title = 'Clients'
  }, [])

  useEffect(() => {
    let sortedData = [...clientProjects]
    if (sortOption === 'Alphabetical') {
      sortedData.sort((a, b) => {
        return a.client.client_name.localeCompare(b.client.client_name)
      })
    } else if (sortOption === 'Date Added') {
      sortedData.sort((a, b) => new Date(a.client.created_at) - new Date(b.client.created_at))
    } else if (sortOption === 'Date Modified') {
      sortedData.sort((a, b) => new Date(a.client.last_updated) - new Date(b.client.last_updated))
    }
    setSortedProjects(sortedData)
  }, [sortOption, clientProjects])

  return (
    <div>
      <TabNameContainer />

      <div className='display-control-container'>
        <SortDropdown onSelect={handleSortChange} selectedOption={sortOption} options={options} showSortIcon={true} />
        <ListGridToggle />
        {userRole === 'Admin' && <button className='btn-add'>+ Add New Client</button>}
      </div>
      <div className='client-container'>
        {sortedProjects.map((data, index) => (
          <div className='client-details' key={index}>
            <div className='client-avatar'>{getInitials(data.client.client_name)}</div>
            <div>{capitalizeFirstLetterOfEachWord(data.client.client_name)}</div>
            <div className='project-count'>{data.projects.length} projects</div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ClientsPage
