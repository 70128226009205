import React, { useEffect } from 'react'
import TabNameContainer from './TabNameContainer'

const BrandLibrary = () => {
  useEffect(() => {
    document.title = 'Brand Libraries'
  }, [])
  return <TabNameContainer />
}

export default BrandLibrary
