export const membersRes = [
  {
    user_id: 1,
    user_name: 'Test User 1',
    user_email: 'test1@abc.com',
    user_role: 3,
    added_at: '2024-08-28T11:01:25.000Z'
  },
  {
    user_id: 2,
    user_name: 'Test User 2',
    user_email: 'test2@abc.com',
    user_role: 1,
    added_at: '2024-08-14T16:09:57.000Z'
  },
  {
    user_id: 3,
    user_name: 'Test User 3',
    user_email: 'test3@abc.com',
    user_role: 3,
    added_at: '2024-10-16T14:46:26.000Z'
  }
]
