// DataContext.js
import React, { createContext, useState, useEffect, useContext } from 'react'
// import axios from 'axios'
import { projectsRes } from './clientandproject'
import { membersRes } from './members'
import { clientRes } from './userandclient'
// import { getUserNameFromLocalStorage, getUserRoleFromLocalStorage } from 'common/helpers'
import { getUserIdFromLocalStorage } from 'config/apiConfig'

// Create Context
const DataContext = createContext()

// Create custom hook to use the DataContext
export const useData = () => useContext(DataContext)

// Provider component
export const DataProvider = ({ children }) => {
  const [clients, setClients] = useState([])
  const [projects, setProjects] = useState([])
  const [members, setMembers] = useState([])
  const userId = getUserIdFromLocalStorage()
  // const userRole = getUserRoleFromLocalStorage()
  // const userName = getUserNameFromLocalStorage()

  useEffect(() => {
    fetchData()
  }, [])

  // Fetch data function
  const fetchData = async () => {
    try {
      //   const [clientsRes, projectsRes] = await Promise.all([
      //     axios.get('/api/clients'),
      //     axios.get('/api/projects')
      //   ]);
      setClients(clientRes)
      setProjects(projectsRes)
      setMembers(membersRes)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const filteredClients = clients.filter((client) => client.user_id == userId)

  const clientProjects = clients.map((client) => {
    const projectsForClient = projects.filter((project) => project.client_id === client.client_id)
    return { client, projects: projectsForClient }
  })

  const projectsAssociatedWithUser = clientProjects.flatMap(({ projects }) => projects)

  return (
    <DataContext.Provider value={{ clients, projects, members, filteredClients, clientProjects, projectsAssociatedWithUser }}>
      {children}
    </DataContext.Provider>
  )
}
