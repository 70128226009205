import React from 'react'
import { useLocation } from 'react-router'

const tabNames = [
  { tabName: 'Clients', route: 'clientsnew' },
  { tabName: 'Projects', route: 'projectsnew' },
  { tabName: 'Brand Libraries', route: 'brand-librariesnew' },
  { tabName: 'Members', route: 'membersnew' },
  { tabName: 'Accounts', route: 'membersnew/:userId' },
  { tabName: 'Templates', route: 'templatesnew' },
  { tabName: 'Archive', route: 'archivenew' }
]

const TabNameContainer = () => {
  const location = useLocation()
  const pathNames = location.pathname.split('/').filter(Boolean)

  const getTabName = () => {
    const baseRoute = pathNames[1]
    const isUserRoute = /membersnew\/\d+/.test(location.pathname)
    if (isUserRoute) {
      return { tabName: 'Accounts', renderPath: true }
    }
    const tab = tabNames.find((tab) => tab.route === baseRoute)
    return { tabName: tab.tabName, renderPath: false }
  }

  const getBreadcrumbPath = () => {
    const relevantPathNames = pathNames.slice(1)

    return relevantPathNames
      .map((segment, index) => {
        const route = relevantPathNames.slice(0, index + 1).join('/')
        const matchedTab = tabNames.find((tab) => {
          const routeParts = tab.route.split('/')
          const segmentParts = route.split('/')
          if (routeParts.length !== segmentParts.length) return false
          return routeParts.every((part, i) => {
            return part.startsWith(':') || part === segmentParts[i]
          })
        })

        if (matchedTab && matchedTab.route.includes(':') && index === relevantPathNames.length - 1) {
          return matchedTab.tabName
        }

        return matchedTab ? matchedTab.tabName : segment
      })
      .map((segment, index, arr) => {
        if (index === arr.length - 1) {
          return (
            <span key={index} style={{ color: '#60767e' }}>
              {segment}
            </span>
          ) //last item
        }
        return (
          <span key={index}>
            <span style={{ color: '#60767e' }}>{segment}</span> <span style={{ color: 'white' }}> {' > '}</span>
          </span>
        )
      })
  }

  const { tabName, renderPath } = getTabName()

  return (
    <div
      className='tab-name-container'
      style={renderPath ? { flexDirection: 'column', justifyContent: 'space-between', alignItems: 'start' } : {}}
    >
      {renderPath && <div className='path'>{getBreadcrumbPath()}</div>}
      <div>{tabName}</div>
    </div>
  )
}

export default TabNameContainer
