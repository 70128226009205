// Add all the common helper related code to this file which can be reused across all other files
export const handleLaunchEditor = (project_id, version_id = null) => {
  const isLocalhost = window.location.hostname === 'localhost'
  const userID = localStorage.getItem('user_id')
  const token = localStorage.getItem('token')
  const userRole = localStorage.getItem('user_role')

  // Define the redirect URL based on whether it's localhost or not
  const redirectUrl = isLocalhost ? process.env.REACT_APP_EDITOR_LOCAL_URL : process.env.REACT_APP_EDITOR_SERVER_URL

  let finalUrl

  if (!version_id && project_id && (typeof project_id === 'number' || typeof project_id === 'string')) {
    finalUrl = `${redirectUrl}?user_id=${userID}&token=${token}&project_id=${project_id}&user_role=${userRole}&version_id=1`
  } else if (
    version_id &&
    (typeof version_id === 'number' || typeof version_id === 'string') &&
    project_id &&
    (typeof project_id === 'number' || typeof project_id === 'string')
  ) {
    finalUrl = `${redirectUrl}?user_id=${userID}&token=${token}&project_id=${project_id}&version_id=${version_id}&user_role=${userRole}`
  } else {
    finalUrl = `${redirectUrl}?user_id=${userID}&token=${token}&user_role=${userRole}`
  }
  // Open the URL in the same tab
  // window.location.href = finalUrl
  window.open(finalUrl, '_blank')
}

export const handleLaunchEditorBaseTemplate = (baseTemplateID) => {
  const isLocalhost = window.location.hostname === 'localhost'
  const userID = localStorage.getItem('user_id')
  const token = localStorage.getItem('token')
  const userRole = localStorage.getItem('user_role')

  // Define the redirect URL based on whether it's localhost or not
  const redirectUrl = isLocalhost ? process.env.REACT_APP_EDITOR_LOCAL_URL : process.env.REACT_APP_EDITOR_SERVER_URL

  let finalUrl

  if (userID && token && isEditor() && baseTemplateID) {
    finalUrl = `${redirectUrl}?user_id=${userID}&token=${token}&baseTemplateID=${baseTemplateID}&user_role=${userRole}`
    // Open the URL in the same tab
    // window.location.href = finalUrl
  } else {
    finalUrl = `${redirectUrl}?user_id=${userID}&token=${token}&user_role=${userRole}&fromExploreTemplates=${baseTemplateID}`
    // Open the URL in the same tab
    // window.location.href = finalUrl
  }
  window.open(finalUrl, '_blank')
}

export function setCookie(name, value, days) {
  const expirationDate = new Date()
  expirationDate.setTime(expirationDate.getTime() + days * 24 * 60 * 60 * 1000)

  const expires = `expires=${expirationDate.toUTCString()}`
  document.cookie = `${name}=${value}; ${expires}; path=/`
}

export function deleteCookie(name) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
}

export function getCookie(name) {
  const cookies = document.cookie.split(';')

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim()
    if (cookie.startsWith(name + '=')) {
      return cookie.substring(name.length + 1)
    }
  }

  return null
}

export function isEditor() {
  return localStorage.getItem('user_role') != 3
}

export const getHighestCurrentVersion = (projectVersionsList) => {
  if (projectVersionsList && projectVersionsList.length > 0) {
    const arr = projectVersionsList[0]?.version_ids?.split(',').reverse().map(Number)
    if (arr?.length > 0) return Math.max(...arr)
  }
  return null
}

export const getUserRoleFromLocalStorage = () => {
  const userRole = Number(localStorage.getItem('user_role'))
  if (userRole === 1) return 'Admin'
  return 'User'
}

export const getUserNameFromLocalStorage = () => {
  return JSON.parse(localStorage.getItem('username'))
}

export const capitalizeFirstLetterOfEachWord = (str) => {
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

export const getInitials = (name) => {
  if (!name) return ''
  const words = name.trim().split(' ')
  if (words.length === 1) return words[0][0].toUpperCase()
  return (words[0][0] + words[1][0]).toUpperCase()
}
