import React, { useState, useMemo } from 'react'
import './modal.scss'
import { capitalizeFirstLetterOfEachWord, getInitials } from 'common/helpers'
import { useData } from 'context/DataContext'

const AddToClient = ({ onClose, member }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedClients, setSelectedClients] = useState([])
  const [selectedMembers, setSelectedMembers] = useState([member] || [])
  const { clients, members, clientProjects } = useData()
  const [hideOptions, setHideOptions] = useState(true)

  const handleSearch = (event) => {
    setSearchTerm(event.target.value)
    setHideOptions(false)
  }

  const toggleClientSelection = (client) => {
    setSelectedClients((prev) => (prev.includes(client) ? prev.filter((c) => c !== client) : [...prev, client]))
  }

  const filteredMembers = useMemo(() => {
    return members.filter(
      (m) =>
        !selectedMembers.map((selected) => selected.user_id).includes(m.user_id) &&
        (m.user_name.toLowerCase().includes(searchTerm.toLowerCase()) || // Match by user_name
          m.user_email.toLowerCase().includes(searchTerm.toLowerCase())) // or user_email
    )
  }, [searchTerm, selectedMembers])

  const handleSelectUser = (user) => {
    setSearchTerm(user.user_email)
    setHideOptions(true)
  }

  const handleAddUsersToClient = () => {
    const selectedUser = members.filter((member) => member.user_email === searchTerm)
    if (selectedUser) setSelectedMembers((prev) => [...prev, ...selectedUser])
    setSearchTerm('')
  }

  const findAssociatedMembers = (clientId) => {
    return clients.filter((client) => client.client_id === clientId).length
  }

  return (
    <div className='modal-overlay'>
      <div className='user-client-modal'>
        <button className='close-btn' onClick={onClose}>
          ×
        </button>
        <h2 style={{ display: 'flex' }}>Add Users to Clients</h2>

        <div className='member-info'>
          {selectedMembers.length &&
            selectedMembers.map((member, index) => {
              return (
                <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                  <div className='profile-picture'>
                    <div className='upload-photo'>{getInitials(member.user_name)}</div>
                  </div>
                  <div className='profile-info'>
                    <div style={{ fontSize: '13px', fontWeight: 600 }}>{member.user_name}</div>
                    <div style={{ fontSize: '13px', marginTop: '2px' }}>{member.user_email}</div>
                  </div>
                </div>
              )
            })}
        </div>

        <div className='user-filter'>
          <input type='text' placeholder='Add names or emails' value={searchTerm} onChange={handleSearch} />
          <button className='add-user-to-top' onClick={handleAddUsersToClient}>
            Add
          </button>

          {searchTerm && !hideOptions && (
            <ul className='dropdown'>
              {filteredMembers.length > 0 ? (
                filteredMembers.map((user) => (
                  <li key={user.user_id} className='dropdown-item' onClick={() => handleSelectUser(user)}>
                    <div className='user-info'>
                      <div className='user-details'>
                        <span>{user.user_name}</span>
                        <span>{user.user_email}</span>
                      </div>
                    </div>
                  </li>
                ))
              ) : (
                <li className='dropdown-item'>No users match the search term.</li>
              )}
            </ul>
          )}
        </div>

        <div className='client-list'>
          <p>Teams</p>
          {clients.map((client, index) => (
            <div key={index} className='client-item'>
              <input type='checkbox' checked={selectedClients.includes(client)} onChange={() => toggleClientSelection(client)} />
              <div>
                <span style={{ display: 'block' }}>{capitalizeFirstLetterOfEachWord(client.client_name)}</span>
                <span style={{ display: 'block', fontSize: '9px', fontWeight: 300, marginTop: '4px' }}>
                  {`${
                    clientProjects.filter((item) => item.client.client_id == client.client_id)[0].projects?.length
                  } Projects, ${findAssociatedMembers(client.client_id)} Members`}
                </span>
              </div>
            </div>
          ))}
        </div>

        {/* Enable button only when clients and users are selected */}
        <button
          className='btn-add-new-user'
          disabled={selectedMembers.length === 0 || selectedClients.length === 0}
          onClick={handleAddUsersToClient}
          style={{ marginRight: '13px' }}
        >
          Add User(s) to Client(s)
        </button>
      </div>
    </div>
  )
}

export default AddToClient
